<template id="tmplHelp">
  <div id="helpMainDiv">
    <h1 class="text-primary" id="helpTitle">Help/FAQ</h1>
    <b-card class="mt-3 p-2" id="helpCard">
      <div v-for="section in sections" :key="section.title" class="mb-4" :id="camelize(section.title.trim())">
        <div v-if="canShow(section.internalOnly)" :id="camelize(section.title.trim())+'Body'">
          <h4 :id="camelize(section.title.trim())+'Title'">{{ section.title }}</h4>
          <TfHelpCard
            v-for="topic in section.topics"
            :key="topic.id"
            :title="topic.title"
            :id="topic.id"
            :expanded="expanded"
            v-on:expand="expand($event)">
            <slot :id="'slotFor'+topic.id">
              <div v-html="topic.html" :id="'htmlDivFor'+topic.id"></div>
            </slot>
          </TfHelpCard>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import TfHelpCard from "@/components/help/HelpCard";
import { isExternalUser } from "@/shared/utils/permissions";

export default {
  name: "Help.vue",

  components: { TfHelpCard },

  data() {
    return {
      expanded: "",
      sections: [
        {
          title: "My Account",
          internalOnly: false,
          topics: [
            {
              id: "sign-in-redirect",
              title: "Signing in to TripFocus", //
              html: `<b-row>
                      <b-col cols="12">
                      <p>
                      <p>The TripFocus sign in process has been improved for a more secure experience. 
                      Users will now be authenticated through a central authentication provider. 
                      Please click <a href="https://drive.google.com/file/d/1yLtYj4SXVjvFyaI6NLxAC9naFhSM38yF/view?usp=drive_link" target="_blank" rel="noopener">here</a> 
                      to view the 'Sign in' help document.&nbsp;</p>
                      </b-col>
                    </b-row>`
            },
            {
              id: "forgot-password",
              title: "Forgot password", //
              html: `<b-row>
                      <b-col cols="12">
                      <p>
                      <p>Please click <a href="https://drive.google.com/file/d/1fAh-h7fZUQzqKPPphR5tOGiBEW1eqpcM/view?usp=drive_link" target="_blank" rel="noopener">here</a> 
                      to view the 'Forgot Password' help document.&nbsp;</p>
                      </b-col>
                    </b-row>`
            }
          ]
        },
        {
          title: "Reference Guides",
          internalOnly: false,
          topics: [
            {
              id: "dashboard-mapping-dashboard",
              title: "Mapping Dashboard",
              html: `<b-row>
                      <b-col cols="12">
                      <p>
                      The TripFocus Enhanced Mapping Dashboard will display all of the orders that are currently active (planned, started or dispatched status). 
                      Underneath the map, is the list of orders that correspond to the map</p>
                      <p>Please click <a href="https://drive.google.com/file/d/1pr8g-GJxpa645B8Dd97FJVud_BO3aHjh/view?usp=drive_link" target="_blank" rel="noopener">here</a> to view the full help documentation for the 
                      TripFocus Enhanced Mapping Dashboard.</p>
                      </b-col>
                    </b-row>`
            },
            {
              id: "reference-guides-notifications",
              title: "Notifications",
              html: `<b-row>
                        <b-col cols="12">
                        <p>Please click <a href="https://drive.google.com/file/d/1EAmhQZncJ1UvfeeAFvjNnmAOrOmgpEpS/view?usp=drive_link" target="_blank" rel="noopener">here</a> to view the full help documentation for TripFocus
                        Notifications.</p>
                        </b-col>
                     </b-row>`
            },
            {
              id: "reference-guides-notifications-definitions",
              title: "Notification Definitions",
              html: `<b-row>
                        <b-col cols="12">
                        <p>Please click <a href="https://drive.google.com/file/d/1KCkm89ip_HpTpVfQncrUr-VAo_JxOHvC/view?usp=drive_link" target="_blank" rel="noopener">here</a> to view the full help documentation for TripFocus
                        Notification Definitions.</p>
                        </b-col>
                     </b-row>`
            },
            {
              id: "reference-guides-user-guide",
              title: "TripFocus User Guide",
              html: `<b-row>
                        <b-col cols="12">
                        <p>Please click <a href=https://drive.google.com/file/d/1K676WgEzYoTYaLVEwCH2aCZ2RZPthxu-/view?usp=drive_link" target="_blank" rel="noopener">here</a> to view the full TripFocus User Guide.</p>
                        </b-col>
                     </b-row>`
            },
            {
              id: "reference-guides-place-orders",
              title: "How to Place Orders in TripFocus",
              html: `<b-row>
                        <b-col cols="12">
                        <p>Please click <a href="https://drive.google.com/file/d/1JDb1dTqgB-GBHGmvkikOc6DQlWuWDnMK/view?usp=share_link" target="_blank" rel="noopener">here</a> to view the full documentation to place orders
                        in TripFocus.</p>
                        </b-col>
                     </b-row>`
            },
            {
              id: "reference-guides-order-details",
              title: "Order Details in TripFocus",
              html: `<b-row>
                        <b-col cols="12">
                        <p>Please click <a href="https://drive.google.com/file/d/1WzZ4jzh9gsx-LNhxukPCRrsiV-GmplK_/view?usp=drive_link" target="_blank" rel="noopener">here</a> to view the full documention for the order details.</p>
                        </b-col>
                     </b-row>`
            },
            {
              id: "reference-guides-admin-guide",
              title: "Administrator Guide",
              html: `<b-row>
                        <b-col cols="12">
                        <p>Please click <a href="https://drive.google.com/file/d/1t0Qpy9NUO0Yv3P83FMSVgWpBzAXw8LfK/view?usp=drive_link" target="_blank" rel="noopener">here</a> to view the full TripFocus Administrator Guide.</p>
                        </b-col>
                     </b-row>`
            }
          ]
        },
        {
          title: "Miscellaneous",
          internalOnly: false,
          topics: [
            {
              id: "misc-topic-recommended-browser",
              title: "Recommended browsers",
              html: `<b-row><p>TripFocus works best in the newest and last prior version of these browsers:</p>
                      <ul>
                      <li><a href="https://www.google.com/chrome/" target="_blank" rel="noopener">Google Chrome</a></li>
                      <li><a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener">Firefox</a></li>
                      <li><a href="https://www.apple.com/safari/" target="_blank" rel="noopener">Safari</a></li>
                      <li><a href="https://www.microsoft.com/windows/microsoft-edge" target="_blank" rel="noopener">Microsoft Edge</a></li>
                      </ul>
                      <p>Note:&nbsp;Any browser you use needs to have JavaScript turned on.&nbsp;</p>
                      <p>TripFocus will also work in most Android and iOS browsers. </p>
                      </b-row>`
            },
            {
              id: "misc-topic-latest-changes",
              title: "Latest changes/general errors",
              html: `<b-row>Sometimes the web browsers will cache old versions of the TripFocus application. If you are not seeing the latest changes, or are receiving
                      general errors, please see the the Clearing browser cache section below.</b-row>`
            },
            {
              id: "misc-topic-cache",
              title: "Clearing browser cache",
              html: `<b-row>
                      <b><span id="Google_Chrome">Google Chrome &amp; FireFox</span></b>
                        <p>On Windows and Linux, use one of the following:</p>
                        <ul>
                        <li>Hold the&nbsp;Ctrl&nbsp;key and press the&nbsp;F5&nbsp;key.</li>
                        <li>Hold both the&nbsp;Ctrl&nbsp;and Shift keys and press the&nbsp;R&nbsp;key.</li>
                        <li>Hold the&nbsp;Ctrl&nbsp;key and click the&nbsp;Reload&nbsp;button on the navigation toolbar.</li>
                        </ul>
                        <p>On macOS:</p>
                        <ul>
                        <li>Hold both the&nbsp;Cmd&nbsp;and&nbsp;Shift&nbsp;keys and press the&nbsp;R&nbsp;key.</li>
                        <li>Hold the&nbsp;Shift&nbsp;key and click the&nbsp;Reload&nbsp;button on the navigation toolbar.</li>
                        <li>Hold the&nbsp;Ctrl&nbsp;key and click the&nbsp;Reload&nbsp;button on the navigation toolbar.</li>
                        </ul>
                        <b><span id="Safari">Safari</span></b>
                        <p>For version 4 and newer:</p>
                        <ul>
                        <li>Hold down the&nbsp;Shift&nbsp;key and click the&nbsp;Reload&nbsp;toolbar button</li>
                        </ul>
                        <b><span id="Microsoft_Edge">Microsoft Edge</span></b>
                        <p>On Windows:</p>
                        <ul>
                        <li>Hold the&nbsp;Ctrl&nbsp;key and press the&nbsp;F5&nbsp;key.</li>
                        </ul>
                        <b><span id="Internet_Explorer">Internet Explorer</span></b>
                        <p>Use one of the following:</p>
                        <ul>
                        <li>Hold the&nbsp;Ctrl&nbsp;key and press the&nbsp;F5&nbsp;key.</li>
                        <li>Hold the&nbsp;Ctrl&nbsp;key and click the "Refresh" button on the toolbar.</li>
                        </ul>
                    </b-row>`
            }
          ]
        }
      ]
    };
  },
  
  methods: {
    expand(id) {
      this.expanded == id ? (this.expanded = null) : (this.expanded = id);
    },

    canShow(internalOnly) {
      if (internalOnly) {
        return this.isInternal;
      }

      return true;
    },

    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    }
  },

  computed: {
    isInternal() {
      return !isExternalUser(this.$store);
    }
  }
};
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
}
.card {
  margin-bottom: 0.5rem;
}
</style>
