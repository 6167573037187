<template>
  <b-card @click="$emit('expand', id)" style="cursor:pointer">
    <b-row class="align-items-center">
      <b-col>
        <b>{{ title }}</b>
      </b-col>
    </b-row>

    <b-collapse :visible="id === expanded">
      <div class="mt-2">
      <slot></slot>
      </div>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  components: {},
  props: ["id", "title", "expanded"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {}
};
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
}
</style>